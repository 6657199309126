import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import Blank from '../Blank';
import { useIntl } from 'gatsby-plugin-intl';
import { useAppContext } from '../../context/AppContext';

const Button = ({ children, onClick }) => {
  return (
    <div
      css={css`
        cursor: pointer;
        text-align: center;
        color: #2b2c32;
        font-weight: bold;
        background: #ffffff;
        border-radius: 41px;
        width: 148px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        ${Tablet} {
          width: 178px;
          height: 47px;
          line-height: 47px;
          font-size: 16px;
        }
      `}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const SGUDetailsBanner = () => {
  const intl = useIntl();
  const { footerFormRef } = useAppContext();
  const data = useStaticQuery(graphql`
    query {
      BannerWeb: file(
        relativePath: { eq: "asset-allocation/SGU/banner-web.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      BannerIpad: file(
        relativePath: { eq: "asset-allocation/SGU/banner-ipad.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        width: 100%;
        height: 465px;
        background: url(${data[`BannerIpad`].childImageSharp.fluid.src})
          no-repeat;
        background-size: cover;
        background-position: 50%;
        ${Tablet} {
          height: 491px;
          background: url(${data[`BannerIpad`].childImageSharp.fluid.src})
            no-repeat;
          background-size: 100%;
        }
        ${Desktop} {
          height: 602px;
          background: url(${data[`BannerWeb`].childImageSharp.fluid.src})
            no-repeat;
          background-size: cover;
        }
      `}
    >
      {/* 文字内容 */}
      <div
        css={css`
          margin: 146px 0 0 0px;
          padding: 0 20px;
          ${Tablet} {
            margin: 133px 0 0 0px;
            width: 768px;
            padding: 0 34px;
          }
          ${Desktop} {
            margin: 210px 0 0 0px;
            width: 1200px;
            padding: 0 20px;
          }
        `}
      >
        {/* 放名字图片 */}
        <img
          css={css`
            width: 269px;
            ${Tablet} {
              width: 466px;
            }
            ${Desktop} {
              width: 613px;
            }
          `}
          src={`/product/asset-allocation/SGU-name-${intl.locale}.png`}
        ></img>
        <Blank height={[30, 34, 30]} />
        <p
          css={css`
            color: #f8f8f8;
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            ${Tablet} {
              width: 587px;
              font-size: 18px;
              line-height: 24px;
            }
            ${Desktop} {
              width: 695px;
              font-size: 18px;
              line-height: 24px;
            }
          `}
        >
          {intl.formatMessage({
            id: `productDetails.pid-56.banner.description`,
          })}
        </p>
        <Blank height={[30, 34, 30]} />
        <Button
          onClick={() => {
            window.scrollTo({
              top: footerFormRef.current.offsetTop - 20,
              left: 0,
              behavior: 'smooth',
            });
          }}
        >
          {intl.formatMessage({
            id: `productDetails.pid-56.banner.btn`,
          })}
        </Button>
      </div>
    </div>
  );
};
export default SGUDetailsBanner;
