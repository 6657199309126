import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import Blank from '../Blank';

// 时间段选项组件
const Period = ({ text = '', active = false, onClick = () => {} }) => {
  return (
    <div
      css={css`
        width: 100%;
        color: ${active ? '#F8F8F8' : '#999999'};
        background-color: ${active ? '#EE2F79' : '#FFFFFF'};
        height: 23.1px;
        border-radius: 20.5px;

        font-size: 9px;
        font-weight: ${active ? 'bold' : 'normal'};
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        cursor: pointer;
        padding: 0 10px;
        white-space: nowrap;
        ${Desktop} {
          font-size: 12px;
          line-height: 23.1px;
          padding: 0 10px;
          margin-right: 15px;
        }
      `}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

// 图例组件
const Legend = ({ iconColor, name, isBenchmark = false, setShowInfo }) => {
  return (
    <div
      css={css`
        display: flex;
        pointer-events: ${isBenchmark ? 'auto' : 'none'};
        cursor: pointer;
        flex-direction: column;
        ${Desktop} {
          flex-direction: row;
          align-items: center;
        }
      `}
      onMouseOver={() => {
        if (isBenchmark) {
          setShowInfo(true);
        }
      }}
      onMouseOut={() => {
        if (isBenchmark) {
          setShowInfo(false);
        }
      }}
    >
      {/* 图例图标 */}
      <div
        css={css`
          width: 41px;
          height: 3px;
          background: ${iconColor};
          border-radius: 18px;
          margin-right: 8px;
        `}
      ></div>
      <Blank height={[3, 8, 0]} />
      {/* 图例文案 */}
      <div>{name}</div>
    </div>
  );
};

// 图例解释组件
const LegendInfo = ({ innerText }) => {
  return (
    <div
      css={css`
        position: absolute;
        box-shadow: rgb(0 0 0 / 20%) 1px 2px 10px;
        transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
          visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
          left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s,
          top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
        background-color: rgb(255, 255, 255);
        border-width: 1px;
        border-radius: 4px;
        color: rgb(142, 144, 145);
        font: 14px / 21px sans-serif;
        padding: 10px;

        border-color: rgb(255, 255, 255);
        z-index: 2;
        pointer-events: none;
        bottom: 110px;
        height: fit-content;
        ${Tablet} {
          width: 400px;
          right: 20px;
          bottom: 70px;
        }
      `}
      dangerouslySetInnerHTML={{ __html: innerText }}
    ></div>
  );
};

export { Period, Legend, LegendInfo };
