import React, { useRef, useState } from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { useUpdateEffect, useWindowSize, useMount } from 'react-use';
import { useIntl } from 'gatsby-plugin-intl';
import Axios from 'axios';
import SGUSelectRiskType from './SGUSelectRiskType';
import SGUPerformanceDetails from './SGUPerformanceDetails';
import Blank from '../Blank';
import { Period, Legend, LegendInfo } from './BacktestingPicInnerComponents';

var echarts = require('echarts');
const textArr = ['YTD', '1M', '3M', '6M', '1Y', 'Since Launch'];
// showPeriod 结构：{key:value} = {index:range},index表示在时间段栏中出现的顺序。
// -1:all, 0:this year, 其余数字代表月数（例：近半年-6个月）。
const showPeriod = { 0: 0, 1: 1, 2: 3, 3: 6, 4: 12, 5: -1 };
var datalist = [];
var riskDataList = {}; // 存储不同风险的回测数据，减少切换开销

const SGUBackTestingPic = ({
  showLeft = true,
  productName,
  benchmarkName,
  combinationName,
  setYearlyReturn = () => {},
  setRemarks = () => {},
  setMaxDrawDown = () => {},
  riskType,
  product,
}) => {
  const [backtesting, setBacktesting] = useState([]);
  const [benchmark, setBenchMark] = useState([]);
  const intl = useIntl();
  const [selectedPeriod, setSelectedPeriod] = useState(5);
  const [myChart, setMyChart] = useState('');
  const [showBenchmarkInfo, setShowBenchmarkInfo] = useState(false);
  const { width: windowWidth } = useWindowSize();
  benchmarkName = intl.formatMessage({
    id: benchmarkName,
  });
  const smarthkUrl = {
    live: 'https://smarthk.aqumon.com',
    test: 'https://test-smarthk.aqumon.com',
  }[process.env.GATSBY_ENV];
  // 每次跳转新的产品页，要刷新datalist
  useMount(() => {
    datalist = [];
    riskDataList = {};
    if (!myChart) {
      setMyChart(echarts.init(echartsRef.current));
    }
    Axios.get(
      `${smarthkUrl}/api/v2/cms/product/${product.product_id}?language=en&riskType=${product.riskType}&backtesting=1`
    ).then((res) => {
      if (res.data.status.ecode) {
        // 获取失败
        myChart.hideLoading();
      } else {
        setBacktesting(res.data.data.backtesting);
        setBenchMark(res.data.data.benchmark);
      }
    });
  });
  // 监听selectedPeriod的改变
  useUpdateEffect(() => {
    if (!datalist[selectedPeriod]) {
      calculatePeridData(selectedPeriod);
    }
    myChart.clear();
    myChart.setOption(
      EChartOption(
        datalist[selectedPeriod].backtestingData,
        datalist[selectedPeriod].benchmarkData
      )
    );
  }, [selectedPeriod]);
  // 监听showLeft的变化
  useUpdateEffect(() => {
    datalist = [];
    if (!datalist[selectedPeriod]) {
      calculatePeridData(selectedPeriod);
    }
    myChart.setOption(
      EChartOption(
        datalist[selectedPeriod].backtestingData,
        datalist[selectedPeriod].benchmarkData
      )
    );
  }, [showLeft]);
  // 监听benchmark的变化。切换风险类型后会重新set backtesting和benchmark数据，会有异步延迟。故监听benchmark数据变化，重新画图。
  useUpdateEffect(() => {
    if (myChart) {
      datalist = [];
      if (!datalist[selectedPeriod]) {
        calculatePeridData(selectedPeriod);
      }
      myChart.setOption(
        EChartOption(
          datalist[selectedPeriod].backtestingData,
          datalist[selectedPeriod].benchmarkData
        )
      );
      setTimeout(() => {
        myChart.hideLoading();
      }, 1000);
    }
  }, [benchmark]);

  // 因为riskType变化后，会执行setProduct，异步有延迟，故直接监听product的变化，执行setBacktesting和setBenchMark操作。
  useUpdateEffect(() => {
    myChart.showLoading('default', { color: '#ee2f79' });
    if (!riskDataList[riskType]) {
      // 若切换后风险对应的回测数据未曾存储，则请求该风险的回测数据
      Axios.get(
        `${smarthkUrl}/api/v2/cms/product/${product.product_id}?language=en&riskType=${product.riskType}&backtesting=1`
      ).then((res) => {
        if (res.data.status.ecode) {
          // 获取失败
        } else {
          setBacktesting(res.data.data.backtesting);
          setBenchMark(res.data.data.benchmark);
          riskDataList[riskType] = {
            backtesting: res.data.data.backtesting,
            benchmark: res.data.data.benchmark,
          };

          setTimeout(() => {
            myChart.hideLoading();
          }, 1000);
        }
      });
    } else {
      setBacktesting(riskDataList[riskType].backtesting);
      setBenchMark(riskDataList[riskType].benchmark);

      setTimeout(() => {
        myChart.hideLoading();
      }, 1000);
    }
  }, [product]);

  // 当数字是小于10的就要在前面加0,使其规范
  const checkTime = (num) => {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  };

  // 对数据进行归一化处理
  const rebase = (dataList) => {
    if (dataList.length === 0) return [];
    const firstValue = dataList[0]['value'];
    return dataList.map((item) => {
      return ((item['value'] - firstValue) / firstValue) * 100;
    });
  };
  // 计算最大回撤
  const maxDrawdown = (backtesting) => {
    if (backtesting.length === 0) {
      return 0;
    }
    var maxDrawDownValue = 0.0;
    var tempMaxValue = backtesting[0]['value'];
    for (var i = 1; i < backtesting.length; i++) {
      var currentValue = backtesting[i]['value'];
      tempMaxValue = Math.max(tempMaxValue, currentValue);
      maxDrawDownValue = Math.max(
        maxDrawDownValue,
        (tempMaxValue - currentValue) / tempMaxValue
      );
    }
    return maxDrawDownValue;
  };
  const echartsRef = useRef();

  // 取两个数组日期的并集
  const intersection = (backtesting, benchmark) => {
    // backtesting与benchmark数组的结构均为：[{date: '' ,value: ''}, {date: '' ,value: ''}, ...]
    let backtestingIndex = 0; // 记录backtesting数组的下标
    let benchmarkIndex = 0; // 记录benchmark数组的下标
    let resultBacktesting = []; // 存储date为交集的backtesting数据
    let resultBenchmark = []; // 存储date为交集的benchmark数据
    // 因为backtesting与benchmark两个数组中的date都是递增的，可以利用这一点，同时遍历两个数组，获得date为交集的结果数组。
    while (
      backtestingIndex <= backtesting.length - 1 &&
      benchmarkIndex <= benchmark.length - 1
    ) {
      // 比较时间戳的大小
      if (
        backtesting[backtestingIndex].date === benchmark[benchmarkIndex].date
      ) {
        resultBacktesting.push(backtesting[backtestingIndex]);
        resultBenchmark.push(benchmark[benchmarkIndex]);
        backtestingIndex++;
        benchmarkIndex++;
      } else if (
        new Date(backtesting[backtestingIndex].date).getTime() >
        new Date(benchmark[benchmarkIndex].date).getTime()
      ) {
        benchmarkIndex++;
      } else {
        backtestingIndex++;
      }
    }
    return { backtesting: resultBacktesting, benchmark: resultBenchmark };
  };

  // 截取数据的显示范围
  const changeData = (period) => {
    // today:回测数据中最新的一天
    const today = new Date(backtesting.slice(-1)[0]['date']);
    const dataList = backtesting.map(
      (item) => +new Date(item['date'].replace(/-/g, '/'))
    );
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    var calculateData = {
      backtestingData: {},
      benchmarkData: {},
    };
    if (period === -1) {
      // show all
      // 计算backtesting数组与benchmark数组中date的并集后再画图
      let intercectionData = intersection(backtesting.concat([]), benchmark);
      calculateData.backtestingData = intercectionData.backtesting;
      calculateData.benchmarkData = intercectionData.benchmark;
    } else if (period === 0) {
      // show YTD
      day = 1;
      var time = `${year}/01/${checkTime(day)}`;

      let thisTimeStemp = +new Date(time);
      var index = dataList.findIndex((timestemp) => timestemp >= thisTimeStemp);

      // 计算backtesting数组与benchmark数组中date的并集后再画图
      let intercectionData = intersection(
        backtesting.slice(index),
        benchmark.slice(index)
      );
      calculateData.backtestingData = intercectionData.backtesting;
      calculateData.benchmarkData = intercectionData.benchmark;
    } else {
      var range = period;
      if (month - range <= 0) {
        year--;
        month = 12 + month - range;
      } else {
        month = month - range;
      }

      var time = `${year}/${checkTime(month)}/${checkTime(day)}`;

      // 如果没有time这天的数据，就向后一天找，直至找到最近的有数据的一天为止
      let thisTimeStemp = +new Date(time);
      var index = dataList.findIndex((timestemp) => timestemp >= thisTimeStemp);

      // 计算backtesting数组与benchmark数组中date的并集后再画图
      let intercectionData = intersection(
        backtesting.slice(index),
        benchmark.slice(index)
      );
      calculateData.backtestingData = intercectionData.backtesting;
      calculateData.benchmarkData = intercectionData.benchmark;
    }
    return calculateData;
  };

  const calculatePeridData = (peridIndex) => {
    datalist[peridIndex] = changeData(showPeriod[peridIndex]);
  };

  const EChartOption = (backtesting, benchmark) => {
    // 两组数据整合
    const seriesData = [
      {
        name: 'backtesting',
        dataList: rebase(backtesting),
      },
      {
        name: 'benchmark',
        dataList: rebase(benchmark),
      },
    ];

    // 最大回撤，历史累计收益率，基准
    setMaxDrawDown((maxDrawdown(backtesting) * 100).toFixed(2) + '%');
    setYearlyReturn(
      Number(seriesData[0]['dataList'].slice(-1)).toFixed(2) + '%'
    );
    setRemarks(Number(seriesData[1]['dataList'].slice(-1)).toFixed(2) + '%');

    // 设置y轴刻度间隔
    const findInterval = (seriesData) => {
      let max = 0;
      let min = 0;
      const step = 2;

      seriesData.forEach((item) => {
        const valueList = item['dataList'];
        const dataListMax = Math.max.apply(null, valueList);
        const dataListMin = Math.min.apply(null, valueList);

        if (max < dataListMax) {
          max = dataListMax;
        }
        if (min > dataListMin) {
          min = dataListMin;
        }
      });
      const interval = +((max.toFixed(2) - min.toFixed(2)) / step);
      return interval;
    };

    const option = {
      baseOption: {
        borderRadius: '15px',
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'rgba(255,255,255,1)',
          borderColor: '#b6ccdc',
          textStyle: {
            color: '#8E9091;',
          },
          extraCssText: 'z-index: 2',
          formatter: function(params) {
            if (params.length === 0) return;
            var res = params[0].name + '<br/>';
            for (var i = params.length - 1; i >= 0; i--) {
              res += `${params[i].marker} ${params[i].seriesName} : ${params[
                i
              ].value.toFixed(2)}%<br/>`;
            }
            return res;
          },
        },
        grid: {
          right: '20',
          left: '70',
        },
        xAxis: {
          // X轴
          position: 'bottom',
          boundaryGap: false,
          type: 'category', // 不使用time因为刻度不能自定义
          splitLine: {
            show: false,
          },
          splitNumber: 2,
          axisLabel: {
            color: 'rgb(153,153,153)',
            formatter(value, index) {
              const xData = backtesting.map((item) => item['date']);
              if (index === 0) {
                return `                  ${value}`;
              }
              if (index === xData.length - 1) {
                return `${value}                `;
              }
              return value;
            },
            align: 'center',
            padding: [4, 0, 0, 0],
            interval(index) {
              const length = backtesting.length;
              if (!length) {
                return true;
              }
              const xData = backtesting.map((item) => item['date']);
              const split = parseInt(xData.length / 2, 10);
              return index % split === 0 || index === xData.length - 1;
            },
          },
          data: backtesting.map((item) => item['date']),
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(153, 153, 153, 0.6)',
              type: 'dotted',
            },
            onZero: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',

          interval: findInterval(seriesData),
          min: 'dataMin',
          max: 'dataMax',
          axisLabel: {
            color: 'rgb(153,153,153)',
            lineStyle: {
              color: 'rgba(255, 255, 255, 0)',
            },
            formatter(value) {
              let temp = '';
              temp = `${value.toFixed(2)}%`;
              return temp;
            },
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: benchmarkName,
            type: 'line',
            data: seriesData[1]['dataList'],
            showSymbol: true,
            symbol: 'circle',

            symbolSize: 2,
            lineStyle: {
              width: 2.5,
            },
            itemStyle: {
              color: '#a7a7a7',
            },
            smooth: 0.1,
            animationThreshold: 400,
          },
          {
            name: productName,
            type: 'line',
            data: seriesData[0]['dataList'],
            showSymbol: true,
            symbol: 'circle',
            symbolSize: 2,
            lineStyle: {
              width: 2.5,
            },
            itemStyle: {
              color: '#ee2f79',
            },
            smooth: 0.1,
            animationThreshold: 400,
          },
        ],
      },
    };
    return option;
  };

  useUpdateEffect(() => {
    myChart.showLoading('default', { color: '#ee2f79' });

    myChart.setOption(EChartOption(backtesting, benchmark));
    document.addEventListener('click', (event) => {
      myChart.dispatchAction({
        type: 'legendAllSelect',
      });
      if (event.target.tagName !== 'CANVAS') {
        myChart.dispatchAction({
          type: 'hideTip',
        });
      }
    });
  }, [myChart]);
  // 监听屏幕宽度的变化，重绘回测图
  useUpdateEffect(() => {
    myChart.resize();
  }, [windowWidth]);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        background: #ffffff;
        box-shadow: 0px 3px 60px 1px rgba(233, 233, 233, 0.8);
        border-radius: 15px;
        width: 325px;
        height: 384px;
        position: relative;
        ${Tablet} {
          margin: 0;
          width: 700px;
          height: 344px;
        }
        ${Desktop} {
          width: 798px;
          height: 378px;
          margin-right: 20px;
        }
      `}
    >
      {/* 图 */}
      <div
        css={css`
          width: 100%;
          height: 100%;
          border-radius: 15px;
          overflow: hidden;
          ${Tablet} {
            width: 700px;
            height: 444px;
          }
          ${Desktop} {
            width: 798px;
            height: 332px;
          }
        `}
      >
        <div id='line-charts' ref={echartsRef} style={{ height: '100%' }}></div>
      </div>
      {/* 基准解释 */}
      {showBenchmarkInfo && (
        <LegendInfo
          innerText={intl.formatMessage({
            id: `productDetails.${combinationName}.benchmark-interpretation`,
          })}
        />
      )}

      <div
        css={css`
          display: flex;
          flex-direction: column-reverse;
          margin-bottom: 15px;
          z-index: 3;
          ${Tablet} {
            flex-direction: row;
            margin-bottom: 23px;
          }
          ${Desktop} {
            margin-bottom: 0;
            margin-top: -15px;
          }
        `}
      >
        {/* 时间段栏 */}
        <div
          css={css`
            display: flex;
            position: relative;
            width: auto;
            height: 28.4px;
            padding: 2.2px 2px 3px 2px;
            border-radius: 24.5px;
            background-color: #ffffff;
            margin: 15px auto;
            ${Tablet} {
              width: auto;
              margin: 0px 0 0px 22px;
            }
          `}
        >
          {new Array(6).fill(0).map((val, i) => {
            return (
              <Period
                key={i}
                index={i}
                text={textArr[i]}
                active={i === selectedPeriod}
                onClick={() => {
                  setSelectedPeriod(i);
                }}
              />
            );
          })}
        </div>
        <Blank width={[0, 58, 30]} />
        {/* 图例组件栏 */}
        <div
          css={css`
            display: flex;
            font-size: 12px;
            line-height: 16px;
            color: #999999;
            margin-left: 31px;
            ${Tablet} {
              margin-left: 0;
            }
          `}
        >
          <Legend iconColor='#EE2F79' name='SmartGlobal UltiMax' />
          <Blank width={[35, 36, 25]} />
          <Legend
            iconColor='#979797'
            name={
              { en: 'Benchmark Index', 'zh-cn': '基准', 'zh-hk': '基準' }[
                intl.locale
              ]
            }
            isBenchmark
            setShowInfo={setShowBenchmarkInfo}
          />
        </div>
      </div>
    </div>
  );
};

export { SGUBackTestingPic, SGUSelectRiskType, SGUPerformanceDetails };
