import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import Blank from '../Blank';
import { useIntl } from 'gatsby-plugin-intl';

const SGUBenefit = ({
  name,
  imgWidth = [],
  imgHeight = [],
  imgUrl = '',
  ...rest
}) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        ${Tablet} {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      `}
      {...rest}
    >
      <div
        css={css`
          text-align: center;
          ${Tablet} {
            text-align: left;
          }
        `}
      >
        <h4
          css={css`
            margin: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            ${Tablet} {
              font-size: 18px;
              line-height: 24px;
            }
            ${Desktop} {
              font-size: 28px;
              line-height: 38px;
            }
          `}
        >
          {intl.formatMessage({
            id: `productDetails.pid-56.three-major-benefits.${name}.title`,
          })}
        </h4>
        <p
          css={css`
            margin: 0;
            margin-top: 10px;
            font-size: 14px;
            line-height: 19px;
            ${Tablet} {
              margin-top: 16px;
              width: 399px;
              font-size: 14px;
              line-height: 20px;
            }
            ${Desktop} {
              width: 584px;
              font-size: 18px;
              line-height: 200%;
            }
          `}
        >
          {intl.formatMessage({
            id: `productDetails.pid-56.three-major-benefits.${name}.description`,
          })}
        </p>
      </div>
      <Blank height={[30, 0, 0]} />
      <div
        css={css`
          background: url(${imgUrl}) no-repeat;
          background-size: cover;
          background-position: center;
          width: ${imgWidth[0]}px;
          height: ${imgHeight[0]}px;
          ${Tablet} {
            width: ${imgWidth[1]}px;
            height: ${imgHeight[1]}px;
          }
          ${Desktop} {
            width: ${imgWidth[2]}px;
            height: ${imgHeight[2]}px;
          }
        `}
      ></div>
    </div>
  );
};

export default SGUBenefit;
