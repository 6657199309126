import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import Slider from 'react-slick';
import Arrow from '../../svgs/smart-stock/Arrow.svg';
import { useIntl } from 'gatsby-plugin-intl';
import Blank from '../Blank';

const RiskPortfolioName = ({ index }) => {
  const intl = useIntl();
  const names = {
    3: { en: 'Balanced', 'zh-cn': '平衡型', 'zh-hk': '平衡型' },
    4: { en: 'Growth', 'zh-cn': '成长型', 'zh-hk': '成長型' },
    5: { en: 'Aggressive', 'zh-cn': '进取型', 'zh-hk': '進取型' },
  };
  return (
    <div
      className='risk-portfolio-name'
      css={css`
        background: linear-gradient(
          90deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 61.84%
        );
        box-shadow: 0px 1px 5px 1px rgba(233, 233, 233, 0.8);
        color: #000000;
        font-weight: 500;
        text-align: center;

        border-radius: 13px;
        width: 112px;
        height: 34px;
        font-size: 18px;
        line-height: 34px;
        margin-right: 10px;
        ${Tablet} {
          width: 86px;
          height: 29.47px;
          font-size: 14px;
          line-height: 29.47px;
          margin-right: 20px;
        }
        ${Desktop} {
          width: 112px;
          height: 34px;
          font-size: 18px;
          line-height: 34px;
          margin-right: 10px;
        }
      `}
    >
      {names[index][intl.locale]}
    </div>
  );
};

// 上按钮
const MoveUp = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={className}
      style={style}
      css={css`
        display: block !important;
        left: -27px;
        margin-top: -1px;
        z-index: 5;
      `}
    >
      <Arrow
        css={css`
          transform: translate(-3px, -55px) rotate(180deg);

          width: 32px;
          ${Tablet} {
            width: 27.8px;
          }
          ${Desktop} {
            width: 32px;
          }
        `}
      />
    </div>
  );
};
// 下按钮
const MoveDown = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={className}
      style={{ ...style }}
      css={css`
        display: block !important;
        z-index: 5;
        right: -20px;
        margin-top: -1px;
      `}
    >
      <Arrow
        css={css`
          transform: translate(-3px, -55px) rotate(0deg);
          width: 32px;
          ${Tablet} {
            width: 27.8px;
          }
          ${Desktop} {
            width: 32px;
          }
        `}
      />
    </div>
  );
};

const SelectRiskType = ({ setRiskType }) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        width: 335px;
        ${Tablet} {
          background: #ffffff;
          box-shadow: 0px 3px 60px 1px rgba(233, 233, 233, 0.8);
          border-radius: 15px;
          width: 331px;
          height: 119px;
          padding: 20px 20px 0px;
        }
        ${Desktop} {
          width: 390px;
          height: 150px;
          padding: 30px 27px 0px;
        }
      `}
    >
      <h4
        css={css`
          display: none;
          margin: 0;

          font-weight: 500;
          color: #333333;
          ${Tablet} {
            display: block;
            font-size: 14px;
            line-height: 19px;
          }
          ${Desktop} {
            font-size: 18px;
            line-height: 24px;
          }
        `}
      >
        {intl.formatMessage({
          id: 'productDetails.pid-56.past-performance.select-risk-type',
        })}
      </h4>

      <div
        css={css`
          position: relative;
          overflow: hidden;
          padding: 0 25px;
          margin-top: 20px;
          ${Tablet} {
            padding: 0 27px;
            margin-top: 25.3px;
          }
          ${Desktop} {
            padding: 0 25px;
            margin-top: 26px;
          }
        `}
      >
        {/* 左右两个风险类型按钮上的消失效果（渐变遮罩） */}
        <div
          css={css`
            display: flex;
            position: absolute;
            left: -3px;
            top: 0px;
            z-index: 2;
            ${Tablet} {
              left: 20px;
            }
          `}
        >
          <div
            css={css`
              height: 40px;
              width: 60px;
              background: linear-gradient(
                to left,
                rgb(255, 255, 255, 0),
                rgba(248, 248, 248, 1) 50%
              );
              ${Tablet} {
                background: linear-gradient(
                  to right,
                  #fff,
                  rgba(255, 255, 255, 0)
                );
                position: absolute;
              }
              ${Desktop} {
                position: static;
              }
            `}
          ></div>
          <Blank width={[0, 0, 185]} />
          <div
            css={css`
              width: 60px;
              height: 40px;
              background: linear-gradient(
                to right,
                rgb(255, 255, 255, 0),
                rgba(248, 248, 248, 1) 50%
              );
              position: absolute;
              right: -271px;
              ${Tablet} {
                background: linear-gradient(
                  to left,
                  #fff,
                  rgba(255, 255, 255, 0)
                );
                right: -248px;
              }
              ${Desktop} {
                position: static;
              }
            `}
          ></div>
        </div>
        <Slider
          centerMode
          slidesToShow={1.5}
          adaptiveHeight
          prevArrow={<MoveUp />}
          nextArrow={<MoveDown />}
          initialSlide={0}
          afterChange={(newIndex) => {
            // SGU产品的风险类型为[3,4,5]
            setRiskType(newIndex + 3);
          }}
          css={css`
            width: 100%;
            height: 100%;
            .slick-list {
              padding: 0 150px !important;
              ${Tablet} {
                padding: 0 121px !important;
              }
              ${Desktop} {
                padding: 0px 150px !important;
              }
            }
            .slick-slide {
              margin-top: 3px;
            }
            .slick-center {
              margin-top: 0;
              .risk-portfolio-name {
                background: #ee2f79;
                color: #ffffff;
                box-shadow: none;
                height: 40px;
                line-height: 40px;
                ${Tablet} {
                  height: 34px;
                  line-height: 34px;
                }
                ${Desktop} {
                  height: 40px;
                  line-height: 40px;
                }
              }
            }
          `}
        >
          <RiskPortfolioName index={3} />
          <RiskPortfolioName index={4} />
          <RiskPortfolioName index={5} />
        </Slider>
      </div>
    </div>
  );
};

export default SelectRiskType;
