import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import Blank from '../Blank';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

const SGUIcon = ({ name }) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        display: flex;
        flex: 0 0 50%;
        margin-bottom: 20px;
        ${Tablet} {
          flex: 0 0 40%;
          justify-content: flex-start;
          margin-bottom: 30px;
        }
        ${Desktop} {
          flex: 0 0 25%;
          justify-content: flex-start;
          margin-bottom: 0px;
        }
      `}
    >
      <img
        width={42}
        height={42}
        css={css`
          display: none;
          ${Tablet} {
            display: block;
            margin-top: 7px;
          }
        `}
        src={`/product/asset-allocation/SGU/portfolio-details/${name}.png`}
      ></img>
      <Blank width={[0, 24, 24]} />
      <div
        css={css`
          h5 {
            display: block;
          }
        `}
      >
        <h5
          css={css`
            margin: 0;
            font-size: 14px;
            line-height: 19px;
            ${Tablet} {
              font-size: 18px;
              line-height: 24px;
            }
          `}
        >
          {intl.formatMessage({
            id: `productDetails.pid-56.at-a-glance.${name}.title`,
          })}
        </h5>
        <h5
          css={css`
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            margin-top: 8px;
            font-weight: 500;
            ${Tablet} {
              margin-top: 4px;
              font-size: 20px;
              line-height: 27px;
            }
          `}
        >
          <FormattedHTMLMessage
            id={`productDetails.pid-56.at-a-glance.${name}.description`}
          />
        </h5>
      </div>
    </div>
  );
};

export default SGUIcon;
