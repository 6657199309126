import React, { useState } from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import ProductDetailsDisclaimer from '../../components/ProductDetailsDisclaimer';
import { useIntl } from 'gatsby-plugin-intl';
import Blank from '../Blank';

const SGUPerformanceDetails = ({
  yearlyReturn = 0,
  remarks = 0,
  maxDrawDown = 0,
}) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const intl = useIntl();
  return (
    <>
      <div
        css={css`
          height: 100%;
          padding: 14px 22px 20px 15px;
          border-radius: 11px;
          flex: 0 0 auto;

          background: #ffffff;
          box-shadow: 0px 3px 60px 1px rgba(233, 233, 233, 0.8);

          margin: 20px 0 0 0;
          width: 325px;
          height: 119px;
          padding: 20px 20px 0px;
          border-radius: 15px;
          ${Tablet} {
            width: 360px;
            height: 119px;
            padding: 20px 20px 0px;

            margin: 0 0 0 9px;
          }
          ${Desktop} {
            width: 100%;
            height: 165px;
            margin: 0 0 15px 0;
            padding: 30px 27px 0;
          }
        `}
      >
        {/* 免责声明 */}
        <ProductDetailsDisclaimer
          show={showDisclaimer}
          onClose={() => {
            setShowDisclaimer(false);
          }}
          pid={56}
        />
        <table
          css={css`
            width: 100%;
            .left {
              font-weight: 500;
            }
            ${Tablet} {
              font-size: 14px;
              line-height: 19px;
              td {
                padding-bottom: 10px;
              }
            }
            ${Desktop} {
              font-size: 16px;
              line-height: 22px;
              td {
                padding-bottom: 12px;
              }
            }
          `}
        >
          <tbody>
            <tr>
              <td className='left'>
                {intl.formatMessage({
                  id: `productDetails.pid-32.basic.performance-details.subtitle-1`,
                })}
              </td>
              <td align='right'>{yearlyReturn}</td>
            </tr>
            <tr>
              <td className='left'>
                {intl.formatMessage({
                  id: `productDetails.pid-32.basic.performance-details.subtitle-2`,
                })}
                <Blank width={[8, 8, 8]} style={{ display: 'inline-block' }} />
              </td>
              <td align='right'>{remarks}</td>
            </tr>
            <tr>
              <td className='left'>
                {intl.formatMessage({
                  id: `productDetails.pid-32.basic.performance-details.subtitle-3`,
                })}
              </td>
              <td align='right'>{maxDrawDown}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* 免责声明 */}
      <div
        css={css`
          display: none;
          ${Desktop} {
            display: block;
          }
        `}
      >
        <img src='/icon/circle-i-icon.png' />
        <Blank width={[0, 0, 8]} style={{ display: 'inline-block' }} />
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          css={css`
            color: #999999;
            ${Desktop} {
              font-size: 12px;
              line-height: 16px;
            }
          `}
          onClick={() => {
            setShowDisclaimer(true);
          }}
        >
          {intl.formatMessage({
            id: `productDetails.pid-32.basic.performance-details.disclaimer`,
          })}
        </a>
      </div>
    </>
  );
};

export default SGUPerformanceDetails;
